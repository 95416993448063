<template>
    <div class="content_container mezzanine">
		<div class="content">
			<div class="banner">
				<h1>투자금융</h1>
			</div>
			<ul class="common_tab">
				<li class="current">
					<router-link to="/product/InvestMezzanine">Mezzanine 투자</router-link>
				</li>
				<li>
					<router-link to="/product/InvestPef">신기술투자</router-link>
				</li>
			</ul>
			<h2 class="title_product">Mezzanine 투자<div class="sub_title"></div></h2>
			<section class="overview"> 
				<h3>개요</h3>
				<ul>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/mezzanine/mezzanine_overview_icon01.svg" alt="Mezzanine Invest" />
							<p>Mezzanine<br />Invest</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/mezzanine/mezzanine_overview_icon02.svg" alt="EB" />
							<p>CB, BW, EB</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/mezzanine/mezzanine_overview_icon03.svg" alt="Working Fund" />
							<p>Working<br />Fund</p>
						</div>
					</li>
				</ul>
				<div class="textBox">
					<p>주식과 채권의 중간형태인 전환사채(CB)나 신주인수권부 사채(BW), 교환사채(EB)의 인수를 통한 자금지원이 가능한 상품입니다.</p>
				</div>
			</section>
			<section class="strong">
				<h3>Mezzanine 투자의 장점</h3>
				<div class="float_wrapper">
					<ul class="float_content">
						<li>
							<img src="@/assets/images/sub/mezzanine/icon_investmezzanine_strong_01.svg" alt="자기자본비율 상승" />
							<p class="title">자기자본비율 상승</p>
							<p class="text">채권의 주식전환 시 자본확충을 통한 자기자본비율 상승 등 자산건전성 제고</p>
						</li>
						<li>
							<img src="@/assets/images/sub/mezzanine/icon_investmezzanine_strong_02.svg" alt="채권상환의무無" />
							<p class="title">채권상환의무無</p>
							<p class="text">채권(CB, BW)의 대용납입을 통한 주식전환 시 채권상환 의무 없음</p>
						</li>
						<li>
							<img src="@/assets/images/sub/mezzanine/icon_investmezzanine_strong_03.svg" alt="낮은 조달금리" />
							<p class="title">낮은 조달금리</p>
							<p class="text">대출대비 상대적으로 낮은 조달금리 적용 및 1년 이상의 중장기 자금조달 가능</p>
						</li>
					</ul>
				</div>
			</section>
			<section class="structure">
				<h3>운용구조</h3>
				<div class="structure_infogp">
					<p><img src="@/assets/images/sub/mezzanine/mezzanine_structure_infogp.png" alt="무궁화캐피탈 일반리스 운용구조" /></p>
				</div>	
			</section>
			<section class="process">
				<h3>업무 프로세스</h3>
				<div class="float_wrapper">
					<ul class="float_content">
						<li>
							<div class="num">1</div>
							<img src="@/assets/images/sub/mezzanine/icon_investmezzanine_process_01.svg" alt="투자건(Deal) 접수" />
							<p class="title">투자건(Deal) 접수</p>
							<p class="text">메자닌 투자 대상 건 Deal의 접수</p>
						</li>
						<li>
							<div class="num">2</div>
							<img src="@/assets/images/sub/mezzanine/icon_investmezzanine_process_02.svg" alt="조사분석" />
							<p class="title">조사분석</p>
							<p class="text">대상회사의 분석 및 가치평가 실행</p>
						</li>
						<li>
							<div class="num">3</div>
							<img src="@/assets/images/sub/mezzanine/icon_investmezzanine_process_03.svg" alt="투자심의" />
							<p class="title">투자심의</p>
							<p class="text">투자실사 보고서 작성, 투자심의위원회 상정 후 투자여부 심의</p>
						</li>
						<li>
							<div class="num">4</div>
							<img src="@/assets/images/sub/mezzanine/icon_investmezzanine_process_04.svg" alt="투자 및 수익실현" />
							<p class="title">투자 및 수익실현</p>
							<p class="text">투자 후 만기보유 또는 권리행사를 통한 자금회수</p>
						</li>
					</ul>
				</div>
			</section>

			<Online />
		</div>
	</div>
</template>

<script>
import Online from "@/components/Online.vue"

export default {
    name : "InvestPrivate",
	components: { 
		Online
	},
}
</script>

